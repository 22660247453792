import { makeAutoObservable } from "mobx";

class FullscreenStore {
  isFullscreen: boolean = false;
  hideNav: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  toggleFullscreen() {
    this.isFullscreen = !this.isFullscreen;
  }

  setFullscreen(fullscreen: boolean) {
    this.isFullscreen = fullscreen;
  }

  setHideNav(hide: boolean) {
    this.hideNav = hide;
  }
}

export const fullscreenStore = new FullscreenStore();
