import { Box, Flex, VStack } from "@chakra-ui/react";
import usePolyfills from "@/libs/hooks/polyfills";
import { Outlet } from "react-router-dom";

const allowList = ["/auth/reset-password", "/auth/post-register"];

export default function AuthLayout() {
  const { getMaxHeightRepresentation } = usePolyfills();
  const maxH = getMaxHeightRepresentation();

  return (
    <Flex minH={maxH} align="center">
      <Box
        left={0}
        top={0}
        height="100%"
        width="100vw"
        position="absolute"
        backgroundImage="https://nightco-asset.b-cdn.net/app/app-cover.png"
        filter="blur(6px)"
        zIndex={-1}
      />
      <Box
        left={0}
        top={0}
        height="100%"
        width="100vw"
        position="absolute"
        backgroundColor="rgba(0, 0, 0, 0.7)"
        zIndex={-1}
      />

      <VStack
        minH={360}
        width={{ base: 350, md: 600 }}
        my={8}
        m="auto"
        bg="#151515"
        borderRadius={10}
        borderWidth={1}
        borderColor="secondary.500"
        shadow={4}
        align="stretch"
        px={10}
        py={20}
        justify="center"
      >
        <Outlet />
      </VStack>
    </Flex>
  );
}
