import getPublicUrl from "@/libs/get-public-url";
import { Avatar, AvatarBadge } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useCachedImage } from "@/libs/hooks/cached-image";
import { creatorStore } from "@/libs/stores/creator.store";

const MobXCreatorAvatar = observer(({ id, size = "lg", ...rest }) => {
  const creator = creatorStore.getCreator(id);
  const navigate = useNavigate();
  const imageUrl = creator?.picture ? getPublicUrl(creator.picture) : null;
  const cachedImageUrl = useCachedImage(imageUrl);
  const picture = cachedImageUrl || imageUrl;

  const goToCreator = () => {
    if (creator?.slug) {
      console.log("goToCreator", creator.slug);
      navigate(`/c/@${creator.slug}`);
    }
  };

  if (!creator) {
    return <div></div>;
  }

  return (
    <Avatar
      role="button"
      src={picture}
      size={size}
      {...rest}
      onClick={goToCreator}
    >
      {creator.isOnline && (
        <AvatarBadge
          position="absolute"
          boxSize=".6em"
          borderWidth=".1em"
          borderColor="primary.100"
          bg="green.500"
          bottom=".3em"
          right=".1em"
        />
      )}
    </Avatar>
  );
});

export default MobXCreatorAvatar;
