import { Box, Flex, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useCallback, useContext, useState } from "react";
import AuthContext from "@/context/auth";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import FAIcon from "@/components/FAIcon";
import { withRetry } from "@/libs/utils/retry";
import { playerStore } from "@/libs/stores/player.store";
import LoadMore from "@/components/LoadMore";
import VideoCard from "./VideoCard";
import exploreStore from "@/libs/stores/explore.store";
import useAPI from "@/libs/hooks/api";
import { creatorStore } from "@/libs/stores/creator.store";

const GroupTitle = ({ name }) => {
  const { user } = useContext(AuthContext);
  return (
    <Flex
      justify="space-between"
      fontWeight="bold"
      color="white"
      align="center"
      as={motion.div}
      initial={{ opacity: 0, y: 10 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.3, ease: "easeOut" }}
    >
      <Text
        fontSize="xl"
        maxW="80%"
        sx={{
          wordBreak: "break-word",
          whiteSpace: "pre-wrap",
        }}
      >
        {name}
      </Text>
      {user && (
        <Box as={Link} to="/explore/videos" fontSize="sm" mr={3}>
          <FAIcon type="angle-right" mr={1} /> 探索更多
        </Box>
      )}
    </Flex>
  );
};

const VideoGroup = observer(({ name, items = [], index }) => {
  const store = exploreStore;
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const api = useAPI();
  const data = exploreStore.groups[index]?.items || [];

  const loadMoreVideos = async () => {
    if (loading || done) return;
    setLoading(true);
    try {
      const currentLength = items?.length || 0;
      if (currentLength === 0) return true;
      const isDone = await withRetry(() =>
        exploreStore.loadMoreVideos(api, index, currentLength),
      );

      if (!isDone?.items) {
        setDone(true);
        return isDone;
      }

      for (const doneElement of isDone.items) {
        if (doneElement) {
          playerStore.inQueue(doneElement);
        }
      }

      return isDone;
    } finally {
      setLoading(false);
    }
  };

  const onVideoClick = useCallback(
    (videoIndex) => () => {
      exploreStore.setCurrentGroupIndex(index);
      playerStore.clear();
      for (const currentVideo of exploreStore.currentVideos) {
        if (currentVideo && typeof currentVideo === "object") {
          playerStore.inQueue(currentVideo);
        }
      }

      playerStore.openPlayList(videoIndex, "explore");
    },
    [index],
  );

  return (
    <Box mt={4} pl={2}>
      <GroupTitle name={name} />
      <Flex
        as={motion.div}
        initial={{ x: 100, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5, ease: "easeOut" }}
        gap={{ base: 2, md: 3 }}
        my={6}
        pr={6}
        overflow="auto"
        sx={{
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {data.map((video, index) => {
          if (!video) return null;
          const creator = creatorStore.getCreator(video.creatorId);
          return (
            <VideoCard
              key={`video-${video.id}-${index}`}
              video={video}
              creator={creator}
              index={index}
              onClick={onVideoClick(index)}
            />
          );
        })}
        <LoadMore
          loader={loadMoreVideos}
          loading={loading}
          ml={4}
          done={done}
        />
      </Flex>
    </Box>
  );
});

export default VideoGroup;
