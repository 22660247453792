import { Box, Flex, Skeleton, SkeletonCircle } from "@chakra-ui/react";
import { motion } from "framer-motion";
import Skeletons from "@/components/Skeletons";

const Loading = () => (
  <motion.div
    initial={{ opacity: 0.8 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0.8 }}
    transition={{ duration: 0.2, ease: "easeInOut" }}
  >
    <Box p={6}>
      <Skeleton
        aspectRatio={9 / 4}
        mb={8}
        speed={0.4}
        startColor="gray.700"
        endColor="gray.600"
      />
      <Box mb={4}>
        <Skeleton
          width={160}
          height={8}
          speed={0.4}
          startColor="gray.700"
          endColor="gray.600"
        />
        <Flex gap={3} my={6} overflow="auto">
          {Array.from({ length: 8 }).map((_, index) => (
            <SkeletonCircle
              key={`creator-skeleton-${index}`}
              boxSize="16"
              minW="16"
              borderRadius="full"
            />
          ))}
        </Flex>
      </Box>
      {Array.from({ length: 3 }).map((_, index) => (
        <Box key={`group-skeleton-${index}`} mb={4}>
          <Skeleton width={160} height={8} />
          <Flex gap={3} my={6} overflow="auto">
            {Array.from({ length: 4 }).map((_, videoIndex) => (
              <Skeletons.Video
                key={`video-skeleton-${index}-${videoIndex}`}
                width={{ base: 40, md: 60 }}
                minW={{ base: 40, md: 60 }}
              />
            ))}
          </Flex>
        </Box>
      ))}
    </Box>
  </motion.div>
);

export default Loading;
