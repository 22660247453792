import { makeAutoObservable } from "mobx";

export class Creator {
  id: string;
  createdAt: number = 0;
  displayName: string = "";
  introduce: string = "";
  picture: string = "";
  slug: string = "";
  updatedAt: number = 0;
  isFollowed: boolean = false;
  followersTotal: number = 0;
  likersTotal: number = 0;
  videosTotal: number = 0;
  lastActiveAt: number = 0;
  cachedAt: number = 0;
  _fetching: boolean = false;
  isOnline: boolean = false;
  userId: string = "";
  chatCharge = 0;

  constructor(data: { id: string } & Partial<Creator> & any) {
    makeAutoObservable(this);
    this.id = data.id;
    this.chatCharge = data?.charges?.chat || 0;
    // Only copy properties that exist in the class
    Object.keys(data).forEach((key) => {
      if (key in this && key !== "status") {
        (this as any)[key] = (data as any)[key];
      }
    });
  }

  private _status: string = "Not Followed";

  get status() {
    return this._status;
  }

  set status(value: string) {
    this._status = value;
  }

  update(data: Partial<Creator>) {
    Object.assign(this, data);
  }

  toggleFollow() {
    this.isFollowed = !this.isFollowed;
    if (this.isFollowed) {
      this.followersTotal += 1;
    } else {
      this.followersTotal = Math.max(0, this.followersTotal - 1);
    }
  }

  revertFollowState() {
    this.isFollowed = !this.isFollowed;
    if (this.isFollowed) {
      this.followersTotal += 1;
    } else {
      this.followersTotal = Math.max(0, this.followersTotal - 1);
    }
  }

  setFetching(value: boolean) {
    this._fetching = value;
  }
}
