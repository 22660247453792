import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import FAIcon from "@/components/FAIcon";
import "swiper/css";
import "swiper/css/virtual";
import useDeviceInfo from "@/libs/hooks/device-info";
import usePolyfills from "@/libs/hooks/polyfills";
import { playerStore } from "@/libs/stores/player.store";
import GestureHint from "@/components/GestureHint";
import MobileNavigation from "@/components/MobileNavigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Virtual } from "swiper/modules";
import VideoPost from "@/components/VideoPost";
import { observer } from "mobx-react-lite";
import { withRetry } from "@/libs/utils/retry";
import { fullscreenStore } from "@/libs/stores/fullscreen.store";

const PREFETCH_DISTANCE = 2;

const ModalContainer = observer(({ children, onClose, store }) => {
  const { getMaxHeightRepresentation } = usePolyfills();
  const navigate = useNavigate();
  const fullscreen = getMaxHeightRepresentation();
  const closePlayer = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("video");
    navigate(`?${searchParams.toString()}`, { replace: true });
    store.close();
  };

  return (
    <Modal isOpen={store.active} onClose={onClose} isCentered size="2xl">
      <ModalOverlay bg="rgba(0,0,0,0.6)" />
      <ModalBody>
        <ModalContent height={fullscreen} position="relative">
          <IconButton
            icon={<FAIcon type="chevron-left" fontSize="2xl" />}
            position="absolute"
            left="4"
            top="4"
            zIndex="modal"
            onClick={() => closePlayer()}
            variant="ghost"
            color="white"
            _hover={{ bg: "transparent" }}
            _focus={{ boxShadow: "none", bg: "transparent" }}
            aria-label="Back"
          />
          {children}
        </ModalContent>
      </ModalBody>
    </Modal>
  );
});

const Playlist = ({
  modal = false,
  onChange,
  loader = () => {},
  done = false,
  autoPlay = true,
  store = playerStore,
}) => {
  const { getMaxHeightRepresentation } = usePolyfills();
  const fullscreen = getMaxHeightRepresentation();
  const { height } = useDeviceInfo();
  const [swiper, setSwiper] = useState(null);
  const navigate = useNavigate();
  const Container = useMemo(() => (modal ? ModalContainer : Box), [modal]);

  const onSlideChange = useCallback(
    async ({ activeIndex }) => {
      if (store.videos[activeIndex]) {
        const videoId = store.videos[activeIndex].id;
        store.play(videoId);
      }
      if (activeIndex >= store.videos.length - PREFETCH_DISTANCE) {
        console.log("loadMoreVideos");
        await withRetry(() => loader());
      }
    },
    [loader, done],
  );

  useEffect(() => {
    if (!store.videos[store.currentIndex]) return;
    const videoId = store.videos[store.currentIndex].id;

    if (!modal) {
      navigate(`/videos/${videoId}`, { replace: true });
    } else {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("video", videoId);
      navigate(`?${searchParams.toString()}`, { replace: true });
    }
  }, [store.currentIndex]);

  const onExit = useCallback(() => {
    console.log("onExit");
  }, [modal, navigate]);

  useEffect(() => {
    if (autoPlay) store.play();

    return () => {
      if (modal) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete("video");
        navigate(`?${searchParams.toString()}`, { replace: true });
      }
      fullscreenStore.setFullscreen(false);
      // store.clear();
    };
  }, []);

  useEffect(() => {
    if (swiper && store.currentIndex !== swiper.activeIndex) {
      console.log(
        "swiper.slideTo",
        store.currentIndex,
        "from",
        swiper.activeIndex,
      );
      swiper.slideTo(store.currentIndex, 0);
    }
  }, [swiper, store.currentIndex]);

  return (
    <Container onClose={onExit} store={store}>
      <Box id="container__videos">
        <GestureHint />
        <Box
          height={{
            base: fullscreenStore.isFullscreen
              ? fullscreen
              : `calc(${fullscreen} - ${MobileNavigation.HEIGHT}px)`,
            md: fullscreen,
          }}
          overflow="hidden"
          bg="primary.100"
        >
          <Swiper
            modules={[Mousewheel, Virtual]}
            direction="vertical"
            style={{ height: `${height - MobileNavigation.HEIGHT}px` }}
            virtual={{
              addSlidesBefore: 2,
              addSlidesAfter: 2,
            }}
            speed={200}
            slidesPerView={1}
            onSwiper={setSwiper}
            initialSlide={store.currentIndex}
            onSlideChange={onSlideChange}
            // onTransitionEnd={onSlideChange}
            touchReleaseOnEdges={false}
            threshold={0}
            resistanceRatio={0.5}
            mousewheel={{
              forceToAxis: true,
              sensitivity: 0,
              thresholdDelta: 30,
              thresholdTime: 70,
            }}
          >
            {store.videos.map((player, index) => (
              <SwiperSlide key={player.id} virtualIndex={index}>
                <VideoPost id={player.id} store={store} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </Container>
  );
};

export default observer(Playlist);
