import {
  EmailAuthProvider,
  linkWithCredential,
  linkWithPhoneNumber,
  linkWithPopup,
  onAuthStateChanged,
  PhoneAuthProvider,
  reauthenticateWithCredential,
  signOut,
  updateEmail as updateUserEmail,
  updatePassword,
  updatePhoneNumber as updateUserPhoneNumber
} from "firebase/auth";
import { doc, onSnapshot, query } from "firebase/firestore";
import useAPI from "@/libs/hooks/api";
import { useFirebase } from "@/libs/hooks/firebase";
import { createContext, useCallback, useEffect, useState } from "react";
import { getToken, isSupported } from "firebase/messaging";

import useCrisp from "@/libs/hooks/crisp";
import { IS_LOCAL } from "@/constants";
import { useUpdateLastActive } from "@/libs/hooks/useUpdateLastActive";

const AuthContext = createContext({ user: null, authLoaded: false });

export const withAuthContext = (Component) => (props) => {
  const { auth, firestore, messaging, createVerifier } = useFirebase();
  const api = useAPI();
  const crisp = useCrisp({ load: false });

  const [user, setUser] = useState(null);
  const [authLoaded, setAuthLoaded] = useState(false);

  useUpdateLastActive(firestore, auth);

  const reauthAndResetPassword = useCallback(
    async (password, newPassword) => {
      const currentUser = auth.currentUser;
      const providers = currentUser?.providerData || [];
      const passwordProvider = providers.find(
        (provider) => provider.providerId === "password"
      );
      if (passwordProvider) {
        await reauthenticateWithCredential(
          currentUser,
          EmailAuthProvider.credential(currentUser.email, password)
        );
      }
      await updatePassword(currentUser, newPassword);
    },
    [auth]
  );

  const linkProvider = useCallback(
    async (provider) => linkWithPopup(auth.currentUser, provider),
    [auth]
  );

  const linkPhoneNumber = useCallback(
    async (phone) =>
      linkWithPhoneNumber(
        auth.currentUser,
        phone,
        createVerifier("recaptcha", () => null)
      ),
    [auth, createVerifier]
  );

  const linkEmail = useCallback(
    async (email, password) =>
      linkWithCredential(
        auth.currentUser,
        EmailAuthProvider.credential(email, password)
      ),
    [auth]
  );

  const verifyPhoneNumber = useCallback(
    async (phone) =>
      new PhoneAuthProvider(auth).verifyPhoneNumber(
        phone,
        createVerifier("recaptcha", () => null)
      ),
    [auth, createVerifier]
  );

  const updatePhoneNumber = useCallback(
    async (verificationId, otp) => {
      const phoneCredential = PhoneAuthProvider.credential(verificationId, otp);
      return updateUserPhoneNumber(auth.currentUser, phoneCredential);
    },
    [auth.currentUser]
  );

  const updateEmail = useCallback(
    async (email) => updateUserEmail(auth.currentUser, email),
    [auth.currentUser]
  );

  const logout = useCallback(async () => {
    try {
      // try delete token from backend
      const token = await getToken(messaging);
      await api.deletePushToken(token);
    } catch (e) {
      console.error(e);
    }
    setUser(null);
    signOut(auth);
    window.location.reload();
  }, [auth, messaging, api]);

  const requestToken = useCallback(async () => {
    const fcmSupported = await isSupported();

    if (fcmSupported && !IS_LOCAL) {
      // bind token device to user
      const permission = Notification.permission;
      if (permission === "granted") {
        const token = await getToken(messaging);
        api.addPushToken(token);
      }
    }
  }, [api, messaging]);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      console.log('onAuthStateChanged', user);
      if (user) {
        const extraData = await api.getProfile();
        setUser({ ...user, ...extraData });
        crisp.login(user);
      }
      // set auth loaded true first so that it won't block rendering
      setAuthLoaded(true);
      if (user) requestToken();
    });
  }, [api, auth, crisp, requestToken]);

  // listen user balance change
  useEffect(() => {
    if (!user?.uid) return;
    const q = query(doc(firestore, "users", user.uid));
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const data = snapshot.data();
      const points = data?.points || { balance: 0 };
      const { pushTargets } = data;
      setUser((user) => ({ ...user, points, pushTargets }));
    });
    return () => unsubscribe();
  }, [api, firestore, user?.uid]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        authLoaded,
        requestToken,
        logout,
        linkProvider,
        linkPhoneNumber,
        linkEmail,
        updatePhoneNumber,
        updateEmail,
        verifyPhoneNumber,
        reauthAndResetPassword
      }}
    >
      <Component {...props} />
    </AuthContext.Provider>
  );
};

export default AuthContext;
