import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  useBoolean,
  useOutsideClick,
} from "@chakra-ui/react";
import Header from "@/components/Header";
import FAIcon from "@/components/FAIcon";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import useAPI from "@/libs/hooks/api";
import AuthContext from "@/context/auth";
import { creatorStore } from "@/libs/stores/creator.store";
import { fullscreenStore } from "@/libs/stores/fullscreen.store";

const ExploreLayout = observer(() => {
  const api = useAPI();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [focused, setFocused] = useBoolean(false);
  const [keyword, setKeyword] = useState(searchParams.get("keyword") || "");
  
  // Update keyword when searchParams changes
  useEffect(() => {
    const searchKeyword = searchParams.get("keyword");
    setKeyword(searchKeyword || "");
  }, [searchParams]);
  const ref = useRef(null);
  const [recommendations, setRecommendations] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const { user } = useContext(AuthContext);

  const resetSearch = useCallback(() => {
    setKeyword("");
    setSearchParams({}, { shallow: true });
    navigate("/explore");
  }, [navigate, setSearchParams]);

  // Search when keyword changes from URL params
  useEffect(() => {
    const searchKeyword = searchParams.get("keyword");
    if (!searchKeyword) {
      setRecommendations(null);
      return;
    }

    async function fetchRecommendations() {
      console.log("searching...", searchKeyword);
      setIsSearching(true);
      setHasSearched(true);
      try {
        const data = await api.searchCreators(searchKeyword);
        setRecommendations(data.map((id) => creatorStore.getCreator(id) || {}));
      } finally {
        setIsSearching(false);
      }
    }

    fetchRecommendations();
  }, [api, searchParams]);

  useOutsideClick({ ref, handler: setFocused.off });

  // Handle scroll
  const lastScrollY = useRef(0);

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;

    // 向下滾動超過 10px 才隱藏
    if (
      currentScrollY > lastScrollY.current &&
      currentScrollY - lastScrollY.current > 10
    ) {
      fullscreenStore.setHideNav(true);
    } else if (
      currentScrollY < lastScrollY.current &&
      lastScrollY.current - currentScrollY > 10
    ) {
      // 向上滾動超過 50px 才顯示
      fullscreenStore.setHideNav(false);
    }

    lastScrollY.current = currentScrollY;
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Box>
      {user && (
        <Header
          px={6}
          align="center"
          bg="#151515"
          style={{
            transform: `translateY(${fullscreenStore.hideNav ? "-100%" : "0"})`,
            transition: "transform 0.5s ease-in-out, opacity 0.3s ease-in-out",
            opacity: fullscreenStore.hideNav ? 0 : 1,
            position: "fixed",
            width: "100%",
            top: "-62px",
            paddingTop: "80px",
            paddingBottom: "30px",
            zIndex: 1000,
          }}
        >
          <InputGroup size="sm" color="black" mt={3}>
            <Input
              bg="white"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  if (location.pathname === "/explore") {
                    navigate("/explore/creators");
                  }
                  setSearchParams(
                    { keyword: e.target.value },
                    { replace: true },
                  );
                }
              }}
              placeholder="搜尋更多創作者、影片..."
              px={4}
              onFocus={() => {
                if (!location.pathname.startsWith('/explore/')) {
                  navigate("/explore/creators");
                }
              }}
              ref={ref}
              rounded={20}
            />
            <InputRightElement pr={4} role="button" onClick={resetSearch}>
              <FAIcon type="circle-xmark" color="#B1B1B1" />
            </InputRightElement>
          </InputGroup>
        </Header>
      )}

      <Outlet />
    </Box>
  );
});

export default ExploreLayout;
