/**
 * 网络性能优化工具
 * 用于预加载资源和优化网络请求
 */

// 预加载图片
export const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

// 预加载多个图片
export const preloadImages = async (sources) => {
  const promises = sources.map(src => preloadImage(src));
  return Promise.all(promises);
};

// 预加载组件
export const preloadComponent = async (importFn) => {
  try {
    await importFn();
    return true;
  } catch (error) {
    console.error('Failed to preload component:', error);
    return false;
  }
};

// 使用 Intersection Observer 懒加载图片
export const setupLazyLoading = () => {
  if (!('IntersectionObserver' in window)) return;
  
  const lazyImages = document.querySelectorAll('[data-lazy]');
  
  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const img = entry.target;
        const src = img.getAttribute('data-lazy');
        if (src) {
          img.src = src;
          img.removeAttribute('data-lazy');
        }
        observer.unobserve(img);
      }
    });
  }, { rootMargin: '200px' });
  
  lazyImages.forEach(img => observer.observe(img));
};

// 优先加载关键资源
export const prioritizeResources = () => {
  // 找到所有非关键的脚本和样式
  const nonCriticalResources = document.querySelectorAll(
    'script[data-priority="low"], link[data-priority="low"]'
  );
  
  // 延迟加载非关键资源
  nonCriticalResources.forEach(resource => {
    if (resource.tagName === 'SCRIPT') {
      resource.setAttribute('defer', '');
    } else if (resource.tagName === 'LINK' && resource.rel === 'stylesheet') {
      resource.media = 'print';
      window.addEventListener('load', () => {
        resource.media = 'all';
      });
    }
  });
};

export default {
  preloadImage,
  preloadImages,
  preloadComponent,
  setupLazyLoading,
  prioritizeResources
};
