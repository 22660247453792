import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Circle,
  Flex,
  Grid,
  GridItem,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Square,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import dailyRewardBackground from "@/assets/images/daily-reward/background.png";
import dailyRewardVIPBackground from "@/assets/images/daily-reward/vip-background.png";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import useAPI from "@/libs/hooks/api";
import { Trans, useTranslation } from "react-i18next";
import Currency from "./Currency";
import dairyRewardImage from "@/assets/images/daily-reward/dairy-reward.webp";
import AuthContext from "@/context/auth";
import useVisible from "@/libs/hooks/visible";

const DAILY_REWARD_Z_INDEX = 1810;

const Stamp = (props) => (
  <Square position="relative" {...props}>
    <Text transform="rotate(20.12deg)" color="white" fontSize="12">
      <Trans i18nKey="daily_rewards.claimed" />
    </Text>
    <Circle position="absolute" size="58px" border="1px #D9D9D9 solid" />
    <Circle position="absolute" size="64px" border="1px #D9D9D9 solid" />
  </Square>
);

// @todo: get UI params from backend
const DailyReward = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const api = useAPI();
  const visible = useVisible();
  const [accumulation, setAccumulation] = useState(0);
  const [rule, setRule] = useState({ rewards: [] });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const vip = !!user?.isVip;

  const total = useMemo(
    () => rule.rewards?.reduce((acc, cur) => acc + cur + (vip ? 500 : 0), 0),
    [rule, vip],
  );

  const onUserBackToApp = useCallback(async () => {
    if (vip) {
      try {
        const result = await api.checkIn();
        if (result?.reward) {
          setAccumulation(result.accumulation);
          setRule(result.rule);
          onOpen();
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [api, onOpen]);

  useEffect(() => {
    if (visible) onUserBackToApp();
  }, [onUserBackToApp, visible]);

  if (!user || !vip) return null;

  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose}>
      <ModalOverlay
        bg="rgba(0,0,0,.92)"
        zIndex={DAILY_REWARD_Z_INDEX}
        onClick={onClose}
      />
      <ModalBody
        zIndex={DAILY_REWARD_Z_INDEX + 1}
        position="relative"
        onClick={onClose}
      >
        <ModalContent bg="transparent" onClick={onClose}>
          <Center>
            <Box position="relative" onClick={onClose}>
              <Image
                width="332px"
                pt={vip ? "20px" : 0}
                src={dairyRewardImage}
                onClick={onClose}
              />
            </Box>
          </Center>
        </ModalContent>
      </ModalBody>
    </Modal>
  );
};

DailyReward.Z_INDEX = DAILY_REWARD_Z_INDEX;

export default DailyReward;
