import { Box, Image } from "@chakra-ui/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { motion } from "framer-motion";
import getPublicUrl from "@/libs/get-public-url";

const BannerSlide = ({ banner }) => (
  <motion.div
    initial={{ opacity: 0.8, scale: 0.98 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0.8, scale: 0.98 }}
    transition={{ duration: 0.3, ease: "easeInOut" }}
  >
    <Box
      as="a"
      width="100%"
      position="relative"
      href={banner.url}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Image
        src={getPublicUrl(typeof banner === "string" ? banner : banner.image)}
        width="100%"
      />
    </Box>
  </motion.div>
);

const Banner = ({ banners }) => (
  <Box p={6}>
    <Swiper
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      pagination={{
        clickable: true,
      }}
      spaceBetween={30}
      loop
      modules={[Autoplay, Pagination, Navigation]}
    >
      {banners.map((banner, index) => (
        <SwiperSlide key={`banners-${index}`}>
          <BannerSlide banner={banner} />
        </SwiperSlide>
      ))}
    </Swiper>
  </Box>
);

export default Banner;
