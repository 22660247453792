export const IS_LOCAL = import.meta.env.VITE_APP_ENV === "local";
export const TP_ID = import.meta.env.VITE_TAPPAY_ID || 0;
export const TP_KEY = import.meta.env.VITE_TAPPAY_KEY || "";
export const TP_ENV = import.meta.env.VITE_TAPPAY_ENV || "sandbox";
export const TP_APPLE_PAY_ID = import.meta.env.VITE_APPLE_PAY_MERCHANT_ID || "";

export const FETCHING_STATUS = {
  IDLE: 0,
  FETCHING: 1,
  FINISHED: 2,
  ERROR: 3,
};

export const PAYMENT_TYPES = {
  CREDIT_CARD: "credit_card",
  APPLE_PAY: "apple_pay",
  GOOGLE_PAY: "google_pay",
};
export const GOOGLE_PAY_TEST_PRIME =
  "gp_test_kjo6i5uthfuehfjgit867584urjfhtyr74ytuhjyu7685jtufyejgitu";
export const APPLE_PAY_TEST_PRIME =
  "ap_test_utigjeyfutj5867uyjhuty47rythfjru485768tigjfheufhtu5i6ojk";

export const DEFAULT_USER_NAME = "未命名使用者";

export const VIP_PLANS = [
  {
    plan: "season",
    planName: "優惠季訂方案",
    cost: 3420,
    perMonth: 1140,
    discount: 180,
    duration: 3,
    image: "/images/vip/vip-season.png",
  },
  {
    plan: "half-year",
    planName: "奢華雙季方案",
    cost: 6480,
    perMonth: 1080,
    discount: 720,
    duration: 6,
    recommended: true,
    image: "/images/vip/vip-2-seasons.png",
  },
  {
    plan: "month",
    planName: "小資月訂方案",
    cost: 1200,
    perMonth: 1200,
    duration: 1,
    image: "/images/vip/vip-monthly.png",
  },
];
export const SUPPORTED_PAYMENT_NETWORKS = ["JCB", "MASTERCARD", "VISA"];
