import { PlayerStore, playerStore } from "@/libs/stores/player.store";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
// @ts-ignore
import "shaka-player/dist/controls.css";
import LoadingSpinner from "./components/LoadingSpinner";
import VideoControls from "./components/VideoControls";

interface VideoPlayerProps {
  videoId: string;
  store: PlayerStore;
}

const ShortsPlayer = React.forwardRef<HTMLDivElement, VideoPlayerProps>(
  ({ videoId, store = playerStore }, ref) => {
    const [duration, setDuration] = useState<number>(0);
    const [position, setPosition] = useState<number>(0);
    const [showBuffering, setShowBuffering] = useState(true);
    const bufferingTimer = useRef<NodeJS.Timeout | null>(null);
    const containerRef = useRef<HTMLDivElement>(document.createElement("div"));

    const video = store.getVideo(videoId);

    //set timeline position
    useEffect(() => {
      const handleTimeUpdate = () => {
        if (store.getActivePlayer()?.id !== videoId) return;
        setPosition(video?.videoRef.currentTime || 0);
        setDuration(video?.videoRef.duration || 0);
      };

      const handleBufferingStart = () => {
        if (bufferingTimer.current) {
          clearTimeout(bufferingTimer.current);
        }
        bufferingTimer.current = setTimeout(() => {
          setShowBuffering(true);
        }, 500);
      };

      const handleBufferingEnd = () => {
        if (bufferingTimer.current) {
          clearTimeout(bufferingTimer.current);
          bufferingTimer.current = null;
        }
        setShowBuffering(false);
      };

      const videoElement = video?.videoRef!;
      if (!videoElement) return;
      videoElement.addEventListener("timeupdate", handleTimeUpdate);
      videoElement.addEventListener("loadstart", handleBufferingStart);
      videoElement.addEventListener("play", handleBufferingEnd);

      return () => {
        videoElement.removeEventListener("timeupdate", handleTimeUpdate);
        videoElement.removeEventListener("loadstart", handleBufferingStart);
        videoElement.removeEventListener("play", handleBufferingEnd);
        if (bufferingTimer.current) {
          clearTimeout(bufferingTimer.current);
        }
      };
    }, []);

    useEffect(() => {
      const container = containerRef.current;
      if (!container || !video) return;

      const shouldAppend = video.id == store.getActivePlayer()?.id;
      if (shouldAppend) {
        if (container && !container.contains(video.videoRef)) {
          runInAction(() => {
            store.showThumbnail = true;
          });
          setTimeout(() => {
            container.appendChild(video.videoRef!);
            runInAction(() => {
              store.showThumbnail = false;
            });
          }, 100);
        }
      } else if (container?.contains(video.videoRef!)) {
        // container.removeChild(video.videoRef!);
      }
    }, [store.currentIndex]);

    return (
      <div className="video-container" ref={containerRef} style={{ zIndex: 2 }}>
        {store.isBuffering && showBuffering && <LoadingSpinner />}
        {<VideoControls position={position} duration={duration} />}
      </div>
    );
  },
);

export default observer(ShortsPlayer);

export type { VideoPlayerProps };
