import { useQuery } from "@tanstack/react-query";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { useFirebase } from "./firebase";
import { useContext } from "react";
import AuthContext from "@/context/auth";

export const useIsCreator = () => {
  const { firestore, auth } = useFirebase();
  const { user } = useContext(AuthContext);

  return useQuery({
    queryKey: ["isCreator", user?.uid],
    queryFn: async () => {
      console.log("useIsCreator queryFn running with user:", user?.uid);
      // await auth.authStateReady();
      if (!user?.uid) {
        console.log("No user ID");
        return { isCreator: false, creatorId: null };
      }

      try {
        const creatorsRef = collection(firestore, "creators");
        console.log("Querying creators collection for userId:", user.uid);

        const q = query(creatorsRef, where("userId", "==", user.uid), limit(1));
        const snapshot = await getDocs(q);

        console.log("Query result:", {
          empty: snapshot.empty,
          size: snapshot.size,
          docs: snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })),
        });

        if (snapshot.empty) {
          console.log("No creator document found");
          return { isCreator: false, creatorId: null };
        }

        const creatorId = snapshot.docs[0].id;
        const creatorData = snapshot.docs[0].data();
        console.log("Found creator:", { id: creatorId, data: creatorData });

        return {
          isCreator: true,
          creatorId: creatorId,
        };
      } catch (error) {
        console.error("Error checking creator status:", error);
        return { isCreator: false, creatorId: null };
      }
    },
    enabled: !!user?.uid,
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });
};
