import { lazy, Suspense, useEffect, useState } from "react";
import { AliveScope } from "react-activation";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AuthLayout from "@/layouts/auth";
import { lazyWithPreload as preload } from "react-lazy-with-preload";
import withContext from "@/context";
import withDebug from "@/libs/hoc/debug";

import ProtectedLayout from "@/layouts/protected";
import UserProfileLayout from "@/layouts/user-profile";
import SemiProtectedLayout from "@/layouts/semi-protected";
import Loading from "@/components/Loading";

// 预加载关键组件
import ExplorePage from "@/pages/explore";
import ExploreLayout from "./layouts/explore";
import { useFirebase } from "@/libs/hooks/firebase";
import useAPI from "@/libs/hooks/api";
import { useMixPanel } from "@/libs/hooks/useMixPanel";
import { creatorStore } from "@/libs/stores/creator.store";
import { AnimatePresence, motion } from "framer-motion";
import networkOptimizer from "@/libs/network-optimizer";

// 懒加载非关键路由
const PlaylistPage = lazy(() => import("@/pages/playlist"));
const DepositPage = lazy(() => import("@/pages/payment/deposit"));
const VIPPage = lazy(() => import("@/pages/payment/vip"));
const PersonalInfoPage = lazy(() => import("@/pages/profile/personal-info"));
const SearchPage = lazy(() => import("@/pages/explore/search"));
const ChatsPage = lazy(() => import("@/pages/chats"));
const ChatPage = lazy(() => import("@/pages/chat"));
const CreatorPage = lazy(() => import("@/pages/creator"));
const FollowedCreators = lazy(
  () => import("@/pages/profile/followed-creators"),
);
const SavedVideos = lazy(() => import("@/pages/profile/saved-videos"));
const UnlockedVideos = lazy(() => import("@/pages/profile/unlocked-videos"));
const MembershipPage = lazy(() => import("@/pages/profile/membership"));
const ErrorPage = lazy(() => import("@/pages/error"));
const PostRegisterPage = lazy(() => import("@/pages/auth/post-register"));
const PaymentLayout = lazy(() => import("./layouts/payment"));
const CreatorDashboard = lazy(() => import("@/pages/creator/dashboard"));
const CreatorLayout = lazy(() => import("@/layouts/creator"));
const CreatorEarningsPage = lazy(() => import("@/pages/creator/earnings"));
const CreatorChatsPage = lazy(() => import("@/pages/creator/chats"));
const CreatorChatPage = lazy(() => import("@/pages/creator/chat"));

const AuthPage = lazy(() => import("@/pages/auth"));
const ResetPasswordPage = lazy(() => import("@/pages/auth/reset-password"));
const ForgetPasswordPage = lazy(() => import("@/pages/auth/forget-password"));
const TappayDepositCallbackPage = lazy(
  () => import("@/pages/callback/tappay/deposit"),
);
const TappayVIPCallbackPage = lazy(() => import("@/pages/callback/tappay/vip"));
const TransactionHistoryPage = lazy(
  () => import("@/pages/profile/transaction-history"),
);
const ManageAccountPage = lazy(() => import("@/pages/profile/manage-account"));
const ChangePasswordPage = lazy(
  () => import("@/pages/profile/change-password"),
);
const BindEmailPage = lazy(() => import("@/pages/profile/bind-email"));
const BindPhonePage = lazy(() => import("@/pages/profile/bind-phone"));
const InstallAppPage = lazy(() => import("@/pages/install-app"));

const NotFound = preload(() => import("@/pages/not-found"));

// 预加载函数 - 将在App组件内部使用
const preloadComponents = async () => {
  const importPromises = [
    import("@/pages/explore/search"),
    import("@/pages/creator"),
  ];
  return Promise.all(importPromises);
};

const PageTransition = ({ children, className = "" }) => (
  <AnimatePresence mode="wait">
    <motion.div
      initial={{ opacity: 0.6 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={className}
      key={window.location.pathname}
    >
      {children}
    </motion.div>
  </AnimatePresence>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorPage />}>
      <Route path="/" element={<SemiProtectedLayout />}>
        <Route index element={<Navigate to="/explore" replace />} />
        <Route
          path="explore"
          element={
            // <KeepAlive id="explore-page" when={() => true}>
            <PageTransition>
              <ExploreLayout />
            </PageTransition>
            // </KeepAlive>
          }
        >
          <Route index element={<ExplorePage />} />
          <Route path="videos" element={<SearchPage type="videos" />} />
          <Route path="creators" element={<SearchPage type="creators" />} />
        </Route>
        <Route path="/videos" element={<PlaylistPage />} />
        <Route path="/videos/:id" element={<PlaylistPage />} />
        <Route
          path="/c/:handle"
          element={
            <PageTransition>
              <CreatorPage />
            </PageTransition>
          }
        />
      </Route>

      <Route path="/auth" element={<AuthLayout />}>
        <Route path="login" element={<AuthPage action="login" />} />
        <Route path="register" element={<AuthPage action="register" />} />
        <Route path="post-register" element={<PostRegisterPage />} />
        <Route path="forget-password" element={<ForgetPasswordPage />} />
        <Route path="reset-password" element={<ResetPasswordPage />} />
      </Route>

      <Route path="/" element={<ProtectedLayout />}>
        <Route path="callback/tappay" element={<TappayDepositCallbackPage />} />
        <Route path="callback/tappay/vip" element={<TappayVIPCallbackPage />} />

        {/*<Route path="/streams/:id?" element={<StreamsPage />} />*/}

        <Route
          path="chats"
          element={
            // <KeepAlive id="chats-page" when={() => true}>
            <PageTransition>
              <ChatsPage />
            </PageTransition>
            // </KeepAlive>
          }
        />
        <Route
          path="chat/:id"
          element={
            <PageTransition>
              <ChatPage />
            </PageTransition>
          }
        />
        <Route
          path="chat/:creatorId/new"
          element={
            <PageTransition>
              <ChatPage />
            </PageTransition>
          }
        />
        {/*<Route*/}
        {/*  path="notifications"*/}
        {/*  element={*/}
        {/*    <PageTransition>*/}
        {/*      <NotificationsPage />*/}
        {/*    </PageTransition>*/}
        {/*  }*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="notifications/:id"*/}
        {/*  element={*/}
        {/*    <PageTransition>*/}
        {/*      <NotificationPage />*/}
        {/*    </PageTransition>*/}
        {/*  }*/}
        {/*/>*/}
        <Route path="payment" element={<PaymentLayout />}>
          <Route path="deposit" element={<DepositPage />} />
          <Route path="vip" element={<VIPPage />} />
        </Route>
        <Route path="profile">
          <Route
            path=""
            element={
              <PageTransition className="flex-1">
                <UserProfileLayout />
              </PageTransition>
            }
          >
            <Route index element={<FollowedCreators />} />
            <Route path="followed-creators" element={<FollowedCreators />} />
            <Route path="unlocked-videos" element={<UnlockedVideos />} />
            <Route path="saved-videos" element={<SavedVideos />} />
          </Route>
          <Route path="membership" element={<MembershipPage />} />
          <Route path="personal-info" element={<PersonalInfoPage />} />
          <Route path="manage-account" element={<ManageAccountPage />} />
          <Route path="bind-email" element={<BindEmailPage />} />
          <Route path="bind-phone" element={<BindPhonePage />} />
          <Route path="change-password" element={<ChangePasswordPage />} />

          <Route
            path="transaction-history"
            element={<TransactionHistoryPage />}
          />
        </Route>
      </Route>
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/install-app" element={<InstallAppPage />} />
      <Route path="/creator" element={<CreatorLayout />}>
        <Route index element={<CreatorDashboard />} />
        {/*<Route path="streams" element={<CreatorStreamPage />} />*/}
        <Route path="earnings" element={<CreatorEarningsPage />} />
        <Route path="chats" element={<CreatorChatsPage />} />
        <Route path="chats/:id" element={<CreatorChatPage />} />
      </Route>
      {/*<Route path="/test" element={<TestPage />} />*/}
      {/*<Route path="/test2" element={<TestPage2 />} />*/}
      <Route path="*" element={<NotFound />} />
    </Route>,
  ),
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      retry: 3,
      refetchOnWindowFocus: false, // 减少不必要的重新获取
      cacheTime: 1000 * 60 * 30, // 30分钟缓存
    },
  },
});

function App() {
  const { firestore, auth } = useFirebase();
  const api = useAPI();
  const [isInitialized, setIsInitialized] = useState(false);
  useMixPanel(auth);

  useEffect(() => {
    const initialize = async () => {
      // 使用 Promise.all 并行初始化
      await Promise.all([
        creatorStore.init(api, firestore), // 初始化 Manager
        // 这里可以添加其他初始化任务
      ]);
      setIsInitialized(true);
    };

    initialize();
  }, []);

  // 单独的useEffect用于预加载组件
  useEffect(() => {
    // 只在应用初始化后预加载
    if (isInitialized) {
      // 使用 requestIdleCallback 在浏览器空闲时预加载
      if (window.requestIdleCallback) {
        window.requestIdleCallback(() => {
          preloadComponents();
          import("@/pages/chats");
        });
      } else {
        // 降级处理
        setTimeout(() => {
          preloadComponents();
          import("@/pages/chats");
        }, 1000);
      }
    }
  }, [isInitialized]);

  // 设置网络优化
  useEffect(() => {
    if (isInitialized) {
      // 在应用初始化后设置懒加载
      networkOptimizer.setupLazyLoading();
      networkOptimizer.prioritizeResources();
    }
  }, [isInitialized]);

  if (!isInitialized) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loading />
        <div className="mt-4 text-center text-gray-500">
          Initializing application...
        </div>
      </div>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <AliveScope>
        <Suspense fallback={<Loading />}>
          <RouterProvider router={router} />
        </Suspense>
      </AliveScope>
    </QueryClientProvider>
  );
}

export default withContext(withDebug(App));
