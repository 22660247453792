import { Flex } from "@chakra-ui/react";
import FAIcon from "@/components/FAIcon";
import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { playerStore } from "@/libs/stores/player.store";

const VideoOverlay = ({ id, onClick, onDoubleClick, store = playerStore }) => {
  const [videoClicked, setVideoClicked] = useState(0);

  const handleVideoClicked = useCallback(
    () => setVideoClicked((v) => v + 1),
    [],
  );

  // handle video clicked
  useEffect(() => {
    if (videoClicked === 1) {
      // click once, toggle video pause
      const t = setTimeout(() => {
        store.isPlaying ? store.videoRef.pause() : store.videoRef.play();
        setVideoClicked(0);
      }, 200);
      return () => clearTimeout(t);
    } else if (videoClicked >= 2) {
      // click twice, toggle video like
      // onDoubleClick();
      setVideoClicked(0);
    }
  }, [videoClicked, onClick, onDoubleClick]);

  return (
    <Flex
      position="absolute"
      left={0}
      top={0}
      width="100%"
      height="100%"
      opacity={store.isPlaying ? 0 : 1}
      transition=".2s all"
      onClick={handleVideoClicked}
    >
      <FAIcon
        type={!store.isPlaying ? "pause" : "play"}
        fontSize="5xl"
        m="auto"
      />
    </Flex>
  );
};

export default observer(VideoOverlay);
