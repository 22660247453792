import { Box, Flex } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { motion } from "framer-motion";
import useAPI from "@/libs/hooks/api";
import { useFirebase } from "@/libs/hooks/firebase";
import exploreStore from "@/libs/stores/explore.store";
import { playerStore } from "@/libs/stores/player.store";
import Playlist from "@/components/Playlist";
import Loading from "./components/Loading";
import Banner from "./components/Banner";
import Group from "./components/Group";

import "swiper/css";
import "swiper/css/pagination";
import { useInView } from "react-intersection-observer";
import { fullscreenStore } from "@/libs/stores/fullscreen.store";

const LazyGroup = ({ group, index, isFirstScreen }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
    rootMargin: "50px 0px",
    initialInView: isFirstScreen,
  });

  return (
    <Box ref={ref}>
      {(inView || isFirstScreen) && <Group {...group} index={index} />}
    </Box>
  );
};

const ExplorePage = observer(() => {
  const api = useAPI();
  const { firestore } = useFirebase();
  const [done, setDone] = useState(false);
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current && firestore && api) {
      exploreStore.initialize(firestore, api);
      initialized.current = true;
    }
    return () => {
      initialized.current = false;
    };
  }, [firestore, api]);

  useEffect(() => {
    return () => {
      playerStore.close();
      fullscreenStore.setHideNav(false);
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0.8 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0.8 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <Box>
        {exploreStore.loading ? (
          <Loading />
        ) : (
          <>
            <Banner banners={exploreStore.banners} />
            <Flex direction="column" overflow="hidden">
              {exploreStore.groups.map((group, index) => (
                <LazyGroup
                  key={group.name}
                  group={group}
                  index={index}
                  isFirstScreen={index < 2}
                />
              ))}
            </Flex>
          </>
        )}
      </Box>
      {exploreStore.currentGroupIndex !== null && (
        <Playlist
          modal
          loader={async () => {
            const groupIndex = exploreStore.currentGroupIndex;
            const currentGroup = exploreStore.groups[groupIndex];
            const currentLength = currentGroup?.items?.length || 0;
            const isDone = await exploreStore.loadMoreVideos(
              api,
              groupIndex,
              currentLength,
            );

            if (isDone.length) {
              for (const doneElement of isDone) {
                if (doneElement) {
                  playerStore.inQueue(doneElement);
                }
              }
            }

            setDone(isDone);
            return isDone;
          }}
          done={done}
        />
      )}
    </motion.div>
  );
});

export default ExplorePage;
