import { installAppStore } from "@/components/InstallAppDrawer";
import firebase from "@/libs/firebase.ts";
import { isPWA } from "@/libs/hooks/pwa";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import localforage from "localforage";
import { makeAutoObservable, runInAction } from "mobx";
import apiClient from "../api";
import { Creator } from "../models/creator";

const TTL = 10 * 60 * 1000; // 10 minutes cache TTL

const storage = localforage.createInstance({
  name: "creatorStoreV3",
});

class CreatorStore {
  creators = new Map<string, Creator>();
  followedCreators: Creator[] = [];
  private initialized = false;

  private unsubscribeFollowers?: () => void;

  constructor() {
    makeAutoObservable(this);

    firebase.auth.onAuthStateChanged((user) => {
      if (user) {
        this.subscribeFollowedCreators();
      } else {
        this.cleanup();
      }
    });
  }

  async toggleFollow(id: string): Promise<void> {
    const creator = this.getCreator(id);
    if (!creator) {
      console.error("Cannot toggle follow - creator not found:", id);
      return;
    }

    if (!isPWA()) {
      console.log("Open install app drawer");
      installAppStore.open();
    }

    try {
      // Optimistically update UIｃ
      creator.toggleFollow();
      this.updateFollowedCreatorsList(creator);

      // Call API
      await (creator.isFollowed
        ? apiClient.followCreator(id)
        : apiClient.unfollowCreator(id));
    } catch (error) {
      // Revert on failure
      creator.revertFollowState();
      this.updateFollowedCreatorsList(creator);

      console.error("Failed to toggle follow:", {
        creatorId: id,
        error,
      });
      throw error;
    }
  }

  getCreator(idOrSlug: string): Creator {
    if (!idOrSlug) return new Creator({ id: "" });
    // Handle slug format
    const isSlug = idOrSlug.startsWith("@");
    const cleanId = isSlug ? idOrSlug.slice(1) : idOrSlug;

    // Try to find by ID first
    let creator = this.creators.get(cleanId);

    // If not found and it's a slug, try to find by slug
    if (!creator && isSlug) {
      creator = Array.from(this.creators.values()).find(
        (c) => c.slug === cleanId,
      );
    }

    if (!creator) {
      creator = new Creator({ id: cleanId });
      this.creators.set(cleanId, creator);
      // Set initial cachedAt to avoid multiple immediate API calls
      creator.cachedAt = Date.now();
      // Use Promise to handle async fetch
      if (this.initialized) {
        Promise.resolve().then(() => this.fetchFromApi(creator!, isSlug));
      }
    } else if (this.isCacheExpired(creator) && !creator._fetching) {
      // Add fetching flag to prevent duplicate requests
      creator.setFetching(true);
      Promise.resolve().then(() => this.fetchFromApi(creator!, isSlug));
    }

    return creator;
  }

  async fetchFromApi(creator: Creator, isSlug: boolean = false) {
    // If already fetching, don't start another fetch
    if (creator._fetching) {
      return creator;
    }

    try {
      creator.setFetching(true);
      const creatorFromApi = await apiClient.getCreator(creator.id);

      // Use runInAction to batch updates
      runInAction(() => {
        if (creatorFromApi) {
          this.updateCreator(creator, creatorFromApi);
        }
        creator.setFetching(false);
      });
    } catch (error) {
      console.error("Failed to fetch creator:", error);
      runInAction(() => {
        creator.setFetching(false);
      });
    }

    return creator;
  }

  async fetchFromFirestore(creator: Creator) {
    const creatorRef = await getDoc(
      doc(firebase.firestore, "creators", creator.id),
    );
    return creatorRef.data();
  }

  updateCreator(creator: Creator, data: any) {
    creator.update(data);
    creator.cachedAt = Date.now();
    creator.chatCharge = data?.charges?.chat || creator.chatCharge || 0;
    this.upsertCreator(creator);
  }

  // 新增或更新 Creator
  upsertCreator(data: { id: string } & Partial<Creator> & any) {
    if (!this.creators.has(data.id)) {
      this.creators.set(data.id, new Creator(data));
    } else {
      this.creators.get(data.id)?.update(data);
    }
    // Save immediately after any creator update
    // this.saveToStorage();
  }

  async saveToStorage() {
    try {
      // Convert creators to a plain array of serialized objects
      const serializableData = Array.from(this.creators.entries()).reduce(
        (acc, [id, creator]) => {
          // Only save non-stale data
          if (!this.isCacheExpired(creator)) {
            acc[id] = {
              id: creator.id,
              createdAt: creator.createdAt,
              displayName: creator.displayName,
              introduce: creator.introduce,
              picture: creator.picture,
              slug: creator.slug,
              updatedAt: creator.updatedAt,
              isFollowed: creator.isFollowed,
              followersTotal: creator.followersTotal,
              likersTotal: creator.likersTotal,
              videosTotal: creator.videosTotal,
              cachedAt: creator.cachedAt,
              chatCharge: creator.chatCharge,
              isOnline: creator.isOnline,
            };
          }
          return acc;
        },
        {} as Record<string, any>,
      );

      await storage.setItem("creators", serializableData);
    } catch (error) {
      console.error("Failed to save creators:", error);
    }
  }

  isCacheExpired(creator: Creator): boolean {
    return Date.now() - creator.cachedAt > TTL;
  }

  private cleanup() {
    // Unsubscribe from listeners
    this.unsubscribeFollowers?.();

    // Clear data
    runInAction(() => {
      this.creators.clear();
      this.followedCreators = [];
    });
  }

  private async init() {
    const creators = await apiClient.getAllCreators();
    creators.forEach((creator: Creator) => {
      this.upsertCreator(creator);
    });
    // this.subscribeFollowedCreators();
    this.initialized = true;
  }

  private subscribeFollowedCreators() {
    // 確保 auth 已經初始化
    if (!firebase.auth.currentUser?.uid) {
      // 等待短暫時間後重試
      setTimeout(() => this.subscribeFollowedCreators(), 1000);
      return;
    }

    const q = query(
      collection(firebase.firestore, "creator-followers"),
      where("userId", "==", firebase.auth.currentUser.uid),
    );

    try {
      this.unsubscribeFollowers = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const creator = this.getCreator(change.doc.data().creatorId);
          const data = change.doc.data();
          if (data.following) {
            if (
              this.followedCreators.findIndex((c) => c.id == creator.id) == -1
            )
              this.followedCreators.push(creator);
          } else {
            this.followedCreators = this.followedCreators.filter(
              (c) => c.id !== data.creatorId,
            );
          }
          if (creator) {
            runInAction(() => {
              creator.isFollowed = data.following;
            });
          }
        });
      });
    } catch (error) {
      console.error("Failed to subscribe to followed creators:", error);
    }
  }

  private updateFollowedCreatorsList(creator: Creator): void {
    if (creator.isFollowed) {
      if (!this.followedCreators.find((c) => c.id === creator.id)) {
        this.followedCreators.push(creator);
      }
    } else {
      this.followedCreators = this.followedCreators.filter(
        (c) => c.id !== creator.id,
      );
    }
  }
}

// 反序列化為 Creator 實例
function deserializeCreator(data: any): Creator {
  return new Creator({
    id: data.id,
    createdAt: data.createdAt,
    displayName: data.displayName,
    introduce: data.introduce,
    picture: data.picture,
    slug: data.slug,
    updatedAt: data.updatedAt,
    isFollowed: data.isFollowed,
    followersTotal: data.followersTotal,
    likersTotal: data.likersTotal,
    videosTotal: data.videosTotal,
    chatCharge: data.charges?.chat || data.chatCharge || 0,
    cachedAt: data.cachedAt,
    isOnline: data.isOnline,
  });
}

// 載入持久化數據
async function loadStore() {
  try {
    const savedData = await storage.getItem("creators");
    if (savedData) {
      const creatorsArray = Object.entries(savedData).map(
        ([id, data]): [string, Creator] => [id, deserializeCreator(data)],
      );
      creatorStore.creators = new Map<string, Creator>(creatorsArray);
    }
  } catch (error) {
    console.error("Failed to load creator store:", error);
  }
}

export const creatorStore = new CreatorStore();
// loadStore();
