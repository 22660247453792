import { Box, Circle, Flex } from "@chakra-ui/react";
import Currency from "./Currency";
import { Link } from "react-router-dom";
import FAIcon from "./FAIcon";
import { useEffect, useState } from "react";

const HEIGHT = 80;

const Header = ({ quickDeposit = false, children, ...props }) => {
  const [visible, setVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  return (
    <Box>
      <Box height={`${HEIGHT}px`} /> {/* Spacer */}
      <Flex
        height={`${HEIGHT}px`}
        position="fixed"
        align="center"
        top={0}
        left={0}
        p={4}
        width="100%"
        bg="#141414"
        color="white"
        zIndex={1000}
        transform={`translateY(${visible ? "0" : "-100%"})`}
        opacity={visible ? 1 : 0}
        transition="transform 0.3s ease-in-out, opacity 0.3s ease-in-out"
        {...props}
      >
        {children}
        {quickDeposit && (
          <Box
            as={Link}
            to="/payment/deposit"
            position="absolute"
            right={5}
            size={12}
          >
            <Currency size={8} />
            <Circle
              position="absolute"
              top={-0.5}
              size={4}
              right={-1.5}
              bg="white"
              boxShadow="3px 3px 0px rgba(0,0,0,0.3)"
            >
              <FAIcon type="plus" color="secondary.500" fontSize={14} />
            </Circle>
          </Box>
        )}
      </Flex>
    </Box>
  );
};
Header.HEIGHT = HEIGHT;

export default Header;
