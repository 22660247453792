import { useCallback, useEffect, useMemo, useState } from "react";

const usePWA = () => {
  const isPWA = useMemo(
    () =>
      !!(
        window.navigator?.standalone ||
        window.matchMedia("(display-mode: standalone)").matches
      ),
    []
  );
  const [prompt, setPrompt] = useState(null);

  const install = useCallback(async () => {
    if (!prompt) throw new Error("NOT_INSTALLABLE");
    const choice = await prompt.prompt();
    if (choice.outcome !== "accepted") {
      throw new Error("USER_REJECT");
    }
    setPrompt(null);
    return new Promise((resolve) =>
      window.addEventListener("appinstalled", () => resolve(true))
    );
  }, [prompt]);

  // try listent to beforeinstallprompt event
  useEffect(() => {
    const listener = (e) => {
      e.preventDefault();
      setPrompt(e);
    };
    window.addEventListener("beforeinstallprompt", listener);
    return () => window.removeEventListener("beforeinstallprompt", listener);
  }, []);

  return useMemo(() => ({ isPWA, install }), [install, isPWA]);
};

export default usePWA;

export const isPWA = () =>
  !!(
    window.navigator?.standalone ||
    window.matchMedia("(display-mode: standalone)").matches
  );