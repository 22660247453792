import { Box } from "@chakra-ui/react";
import { useInView } from "react-intersection-observer";
import { memo } from "react";
import VideoPreview from "@/components/VideoPreview";

const VideoCard = memo(
  ({ video, creator, index, onClick }) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
      rootMargin: "50px 50px 50px 50px",
      threshold: 0,
    });

    return (
      <Box
        ref={ref}
        width={{ base: 32, md: 48 }}
        minW={{ base: 32, md: 48 }}
        onClick={onClick}
      >
        {inView && <VideoPreview {...video} creator={creator} />}
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.video.id === nextProps.video.id &&
      prevProps.creator?.id === nextProps.creator?.id
    );
  },
);

export default VideoCard;
