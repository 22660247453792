import { observer } from "mobx-react-lite";
import CreatorGroup from "./CreatorGroup";
import VideoGroup from "./VideoGroup";

const Group = observer(({ type, name, items = [], index }) => {
  if (type === "creators") {
    return <CreatorGroup name={name} items={items} />;
  }
  return <VideoGroup name={name} items={items} index={index} />;
});

export default Group;
