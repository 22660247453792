// 监听来自 Service Worker 的消息
export const setupServiceWorkerListener = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', (event) => {
      const { type, url } = event.data;
      
      if (type === 'NAVIGATE_TO' && url) {
        console.log('Received navigation request from Service Worker:', url);
        
        // 使用 window.location 进行导航
        window.location.href = url;
      }
    });
    
    console.log('Service Worker message listener set up');
  }
};
