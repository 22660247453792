import { action, makeAutoObservable, runInAction } from "mobx";
import { doc, getDoc } from "firebase/firestore";
import db from "../db";
import firebase from "@/libs/firebase";
import apiClient from "@/libs/api";

const CACHE_KEY = "explore_settings";
const CACHE_DURATION = 15 * 60 * 1000; // 15 minutes

/**
 * @typedef {Object} Banner
 * @property {string} image - Banner image URL
 * @property {string} url - Banner link URL
 */

/**
 * @typedef {Object} ExploreGroup
 * @property {string} type - Group type ('creators' | 'videos')
 * @property {string} name - Group display name
 * @property {Array} items - Group items (creators or videos)
 * @property {boolean} done - Whether all items are loaded
 */

/**
 * @typedef {Object} ExploreSettings
 * @property {Banner[]} banners - Array of banner objects
 * @property {ExploreGroup[]} groups - Array of content groups
 */

class ExploreStore {
  banners = [];
  groups = [];
  loading = true;
  error = null;
  currentGroupIndex = null;
  initialized = false;

  // 用於追蹤已加載的組
  loadedGroups = new Set();

  constructor() {
    makeAutoObservable(this, {
      updateVideoGroups: action.bound,
      setSettings: action.bound,
      loadMoreVideos: action.bound,
      setCurrentGroupIndex: action.bound,
    });
  }

  get currentGroup() {
    return this.currentGroupIndex !== null
      ? this.groups[this.currentGroupIndex]
      : null;
  }

  get currentVideos() {
    return this.currentGroup?.items || [];
  }

  setCurrentGroupIndex(index) {
    this.currentGroupIndex = index;
  }

  setSettings(settings) {
    if (settings) {
      this.banners = settings.banners;
      this.groups = settings.groups;
    }
    this.loading = false;
  }

  setError(error) {
    this.error = error;
    this.loading = false;
  }

  updateVideoGroups(videoGroups) {
    this.groups = this.groups.map((group) =>
      group.type === "videos"
        ? videoGroups.find((vg) => vg.name === group.name) || group
        : group,
    );
  }

  async loadMoreVideos(api, groupIndex, currentLength, limit = 5) {
    try {
      const group = this.groups[groupIndex];

      // 檢查是否需要加載
      if (
        group.done ||
        currentLength === 0 ||
        this.loadedGroups.has(`${groupIndex}-${currentLength}`)
      ) {
        return true;
      }
      console.log(
        `[ExploreStore] Loading more videos for group ${groupIndex} from ${currentLength}...`,
      );

      const { result } = await apiClient.getExploreSettingsByChunk(
        groupIndex,
        currentLength,
        limit,
      );
      console.log(result);

      runInAction(() => {
        if (!group.items) {
          group.items = [];
        }

        // 處理新項目
        if (result.items?.length > 0) {
          const existingIds = new Set(group.items.map((item) => item.id));
          const newItems = result.items.filter(
            (item) => !existingIds.has(item.id),
          );

          if (newItems.length > 0) {
            group.items = [...group.items, ...newItems];
          }
        }

        // 標記加載狀態
        group.done = !result.items || result.items.length < limit;
        this.loadedGroups.add(`${groupIndex}-${currentLength}`);
      });

      return result.items;
    } catch (error) {
      console.error("Failed to load more videos:", error);
      return [];
    }
  }

  async loadFromCache() {
    try {
      const cached = await db.exploreCache.get(CACHE_KEY);
      if (!cached?.data) {
        console.debug("[ExploreStore] Cache MISS");
        return false;
      }

      // Even if cache is expired, we'll show it while fetching fresh data
      console.debug("[ExploreStore] Cache HIT");
      this.setSettings(cached.data);

      const age = Date.now() - cached.timestamp;
      const isFresh = age < CACHE_DURATION;

      if (!isFresh) {
        console.debug("[ExploreStore] Cache expired");
      }

      return isFresh;
    } catch (error) {
      console.error("[ExploreStore] Failed to load from cache:", error);
      return false;
    }
  }

  serializeForCache(data) {
    if (!data) {
      data = {
        banners: this.banners,
        groups: this.groups.map((group) => ({
          ...group,
          items: group.items ? [...group.items] : [], // Create a shallow copy of items array
        })),
      };
    }

    // Convert to plain object to remove MobX observables
    return JSON.parse(JSON.stringify(data));
  }

  async saveToCache(data) {
    try {
      const serializedData = this.serializeForCache(data);

      await db.exploreCache.put({
        key: CACHE_KEY,
        data: serializedData,
        timestamp: Date.now(),
      });
      console.debug("[ExploreStore] Saved to cache");
    } catch (error) {
      console.error("[ExploreStore] Failed to save to cache:", error);
    }
  }

  /**
   * Initialize the explore store
   * @param {Firebase} firestore - Firestore instance
   * @param {Object} api - API instance
   * @returns {Promise<void>}
   */
  async initialize() {
    if (this.initialized) {
      console.debug("[ExploreStore] Already initialized");
      return;
    }

    try {
      // First try to load from cache
      // const isCacheFresh = false;
      const isCacheFresh = await this.loadFromCache();

      runInAction(() => {
        // Show loading only if we have no cached data at all
        this.loading = !this.banners.length && !this.groups.length;
      });

      // Always fetch fresh data unless cache is fresh
      if (!isCacheFresh) {
        await this.fetchFreshData();
      }

      runInAction(() => {
        this.initialized = true;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        console.error("[ExploreStore] Failed to initialize:", error);
        this.error = error;
        this.loading = false;
      });
      throw error;
    }
  }

  async fetchFreshData() {
    try {
      const db = firebase.firestore;
      console.debug("[ExploreStore] Fetching fresh data");

      // First get Firestore doc
      const settingsDoc = await getDoc(doc(db, "app", "explore-settings"));

      if (!settingsDoc.exists()) {
        throw new Error("Explore settings not found");
      }

      // Set initial data from Firestore and mark as not loading
      runInAction(() => {
        const exploreSettings = settingsDoc.data();
        this.setSettings(exploreSettings);
        this.loading = false;
      });

      // Then fetch API data in background
      const apiData = await apiClient.getExplore();

      runInAction(() => {
        if (apiData?.groups) {
          const videoGroups = apiData.groups.filter(
            (group) => group.type === "videos",
          );

          this.updateVideoGroups(videoGroups);
        }

        this.saveToCache();
        this.initialized = true;
      });
    } catch (error) {
      runInAction(() => {
        console.error("[ExploreStore] Failed to initialize:", error);
        this.error = error;
        this.loading = false;
      });
      throw error;
    }
  }
}

export default new ExploreStore();
